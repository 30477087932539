@font-face {
  font-family: 'Proto Grotesk';
  src: url('/fonts/ProtoGrotesk-Bold.woff2') format('woff2'),
    url('/fonts/ProtoGrotesk-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proto Grotesk';
  src: url('/fonts/ProtoGrotesk-Light.woff2') format('woff2'),
    url('/fonts/ProtoGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-variant-ligatures: no-common-ligatures;

  box-sizing: border-box;
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

h1,
h2 {
  margin: 0;
}

body {
  background: black;
  color: white;

  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
}

html {
  overflow: hidden;
}

strong {
  line-height: 1em;
}

svg {
  display: block;
}

.hover-opacity {
  transition: opacity 200ms;
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  .hover-opacity:hover {
    opacity: 0.5;
  }
}
